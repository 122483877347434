@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply w-full h-full flex justify-start items-start;
  font-family: Roboto;
  min-width: 350px;
}

a {
  @apply text-primary underline font-bold cursor-pointer;
}

@layer base {
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  }
}
